import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routeUrls } from '../../config/routes';

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const state = useSelector(state => state);
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={props =>
        state.auth.isAuthenticated ? (
          <Redirect
            to={{
              pathname: routeUrls.dashboard,
              state: { from: location },
            }}
          />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

export default LayoutRoute;
