const isThisDisabled = user => {
  // Uncomment this and comment below to make elections visible only to admins who are verified
  // const disableElections = user.role !== 'ADMIN';

  // Uncomment this and comment above to make elections visible to everybody who is verified
  // const disableElections = false;

  // Uncomment this and comment below to make Results visible only to admins who are verified
  // const showResults = user.role === 'ADMIN';

  const disableElections = true;
  const showResults = false;

  // Uncomment this and comment above to make results visible to everybody who is verified
  // const showResults = true;

  const disableElectionsHallAssembly = user.floor === 'OffCampus / ABH BQs';

  return {
    isDisabledElections: disableElections,
    // || !user.verified,
    isDisabledElectionsExecutive: disableElections,
    // || !user.verified,
    isDisabledElectionsHallAssembly:
      disableElections || disableElectionsHallAssembly,
    // || !user.verified,
    isDisabledElectionResults: !showResults,
    // || !user.verified,
  };
};
export default isThisDisabled;

export const disabled = {
  registration: true,
};
