import { lazy, Suspense } from 'react';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyleWrap = ({ children }) => {
  // const history = useLocation();
  const isAuth = useSelector(state => state.auth.isAuthenticated);
  // const pathname = history.pathname;
  // const dashboardPath = /^\/dashboard$/.test(pathname);
  // const accountPath = /^\/account$/.test(pathname);
  // const adminPath = /^\/admin\/\S+$/.test(pathname);
  // const electionsPath = /^\/elections\/\S+$/.test(pathname);
  // const resultsPath = /^\/results\/\S+$/.test(pathname);
  const NonAuthStyles = lazy(() => import('./NonAuthStyles.js'));
  const GenStyles = lazy(() => import('./GenStyles.js'));
  // const isAuth =
  //   dashboardPath || accountPath || adminPath || electionsPath || resultsPath;
  // useEffect(() => {
  //   if (isAuth) {
  //     if (localStorage.isAuth && localStorage.isAuth === 'auth') {
  //     } else {
  //       localStorage.setItem('isAuth', 'auth');
  //       window.location.reload(true);
  //     }
  //   } else {
  //     if (localStorage.isAuth) {
  //       delete localStorage.isAuth;
  //     }
  //   }
  // }, [isAuth]);
  return (
    <>
      <Suspense fallback={<Loader />}>
        {!isAuth && <NonAuthStyles />}
        <GenStyles />
        {children}
      </Suspense>
    </>
  );
};

export default StyleWrap;
