import {
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  CLEAR_AUTH_MESSAGE,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_USERS,
  VERIFY_USER,
  DELETE_USER,
} from '../types';

const DEFAULT_STATE = {
  message: null,
  isAuthenticated: false,
  user: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !!Object.keys(action.user).length,
        user: action.user,
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        message: action.message,
        user: { ...state.user, ...action.user },
      };
    case CLEAR_AUTH_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        message: action.message,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        message: action.message,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        message: action.message,
      };
    case SET_USERS:
      return {
        ...state,
        adminPanel: {
          ...state.adminPanel,
          users: action.users,
        },
      };
    case VERIFY_USER:
      return {
        ...state,
        adminPanel: {
          ...state.adminPanel,
          message: action.message,
          brownite: action.brownite,
        },
      };
    // case DELETE_USER:
    //   return {
    //     ...state,
    //     adminPanel: {
    //       ...state.adminPanel,
    //       message: action.message,
    //     },
    //   };
    default:
      return state;
  }
};
