export const entries = role => {
  const roles = {
    'hall-chairperson': 'Hall Chairperson',
    'deputy-hall-chairperson': 'Deputy Hall Chairperson',
    'hall-secretary': 'Hall Secretary',
    'finance-minister': 'Finance Minister',
    'information-minister': 'Information Minister',
    'health-minister': 'Health/Sanitation Minister',
    'social-minister': 'Social/Buttery Minister',
    'sports-minister': 'Sports Minister',
    'defence-minister': 'Defense Minister',
    'fa-minister': 'Female Affairs Minister',
    floorRepAGround: 'Floor Rep - Block A, Ground Floor',
    floorRepA1st: 'Floor Rep - Block A, 1st Floor',
    floorRepA2nd: 'Floor Rep - Block A, 2nd Floor',
    floorRepA3rd: 'Floor Rep - Block A, 3rd Floor',
    floorRepBGround: 'Floor Rep - Block B, Ground Floor',
    floorRepB1st: 'Floor Rep - Block B, 1st Floor',
    floorRepB2nd: 'Floor Rep - Block B, 2nd Floor',
    floorRepB3rd: 'Floor Rep - Block B, 3rd Floor',
    floorRepCGround: 'Floor Rep - Block C, Ground Floor',
    floorRepC1st: 'Floor Rep - Block C, 1st Floor',
    floorRepC2nd: 'Floor Rep - Block C, 2nd Floor',
    floorRepC3rd: 'Floor Rep - Block C, 3rd Floor',
    floorRepDGround: 'Floor Rep - Block D, Ground Floor',
    floorRepD1st: 'Floor Rep - Block D, 1st Floor',
    floorRepD2nd: 'Floor Rep - Block D, 2nd Floor',
    floorRepD3rd: 'Floor Rep - Block D, 3rd Floor',
    floorRepEGround: 'Floor Rep - Block E, Ground Floor',
    floorRepE1st: 'Floor Rep - Block E, 1st Floor',
    floorRepE2nd: 'Floor Rep - Block E, 2nd Floor',
    floorRepE3rd: 'Floor Rep - Block E, 3rd Floor',
    floorRepFGround: 'Floor Rep - Block E, Ground Floor',
    floorRepGGround: 'Floor Rep - Block G, Ground Floor',
    floorRepG1st: 'Floor Rep - Block G, 1st Floor',
    floorRepG2nd: 'Floor Rep - Block G, 2nd Floor',
    floorRepG3rd: 'Floor Rep - Block G, 3rd Floor',
  };

  return roles[role];
};

export const arrayOfRoles = [
  { to: 'hall-chairperson', role: entries('hall-chairperson') },
  { to: 'deputy-hall-chairperson', role: entries('deputy-hall-chairperson') },
  { to: 'hall-secretary', role: entries('hall-secretary') },
  { to: 'finance-minister', role: entries('finance-minister') },
  { to: 'information-minister', role: entries('information-minister') },
  { to: 'health-minister', role: entries('health-minister') },
  { to: 'social-minister', role: entries('social-minister') },
  { to: 'sports-minister', role: entries('sports-minister') },
  { to: 'defence-minister', role: entries('defence-minister') },
  { to: 'fa-minister', role: entries('fa-minister') },
];
