import { useState } from 'react';
import {
  MdDashboard,
  MdKeyboardArrowDown,
  MdPowerSettingsNew,
  MdFingerprint,
  MdPeopleOutline,
  MdHome,
  MdWork,
  MdAssessment,
  MdSettings,
} from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';
import { IoMedicalSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import SourceLink from '../SourceLink';
import bn from '../../utils/bemnames';
import isThisDisabled from '../../utils/isThisDisabled';
import { logout } from '../../redux/actions';
import { entries } from '../../data/pollEntries';

const elections2021Executives = [
  {
    to: '/elections/hall-chairperson',
    name: 'Hall Chairperson',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/deputy-hall-chairperson',
    name: 'Deputy Hall Chairperson',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/hall-secretary',
    name: 'Hall Secretary',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/finance-minister',
    name: 'Finance Minister',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/information-minister',
    name: 'Information Minister',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/health-minister',
    name: 'Health/Sanitation Minister',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/social-minister',
    name: 'Social/Buttery Minister',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/sports-minister',
    name: 'Sports Minister',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/defence-minister',
    name: 'Defence Minister',
    exact: false,
    Icon: MdWork,
  },
  {
    to: '/elections/fa-minister',
    name: 'Female Affairs Minister',
    exact: false,
    Icon: MdWork,
  },
];
const elections2021HallAssembly = [
  {
    to: '/elections/floor-reps',
    name: 'Floor Reps',
    exact: false,
    Icon: MdWork,
  },
];
const elections2021Results = [
  {
    to: '/results/executives',
    name: 'Executives',
    exact: false,
    Icon: MdPeopleOutline,
  },
  {
    to: '/results/hall-assembly',
    name: 'Hall Assembly',
    exact: false,
    Icon: MdHome,
  },
];
const adminPanel = [
  {
    to: '/admin/mbbs',
    name: 'Manage MBBS Brownites',
    exact: false,
    Icon: IoMedicalSharp,
  },
  {
    to: '/admin/bds',
    name: 'Manage BDS Brownites',
    exact: false,
    Icon: IoMedicalSharp,
  },
  {
    to: '/admin/bmls',
    name: 'Manage BMLS Brownites',
    exact: false,
    Icon: IoMedicalSharp,
  },
  {
    to: '/admin/pt',
    name: 'Manage PT Brownites',
    exact: false,
    Icon: IoMedicalSharp,
  },
];

const navItems1 = [
  { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
  { to: '/account', name: 'Account', exact: false, Icon: MdSettings },
];
const navItems2 = [
  {
    to: '/login',
    name: 'Logout',
    exact: true,
    Icon: MdPowerSettingsNew,
    onClick: logout,
  },
];
const isDisabledStyle = {
  color: 'grey',
  cursor: 'not-allowed',
  opacity: 0.5,
  textDecoration: 'none',
};

const bem = bn.create('sidebar');
const SideBar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const {
    isDisabledElections,
    isDisabledElectionsExecutive,
    isDisabledElectionsHallAssembly,
    isDisabledElectionResults,
  } = isThisDisabled(user);
  const [state, setState] = useState({
    isOpen2021Elections: !isDisabledElections,
    isOpen2021ElectionsExecutive: !isDisabledElectionsExecutive,
    isOpen2021ElectionsHallAssembly: !isDisabledElectionsHallAssembly,
    isOpen2021ElectionsResults: !isDisabledElectionResults,
    isOpenAdminPanel: true,
  });
  const handleClick = name => () => {
    setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        ...prevState,
        [`isOpen${name}`]: !isOpen,
      };
    });
  };
  const toggleClose = () => {
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
  return (
    <aside className={bem.b()}>
      <div className={bem.e('background')} />
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src="https://res.cloudinary.com/dcjxlbq4z/image/upload/v1612836816/abhelections/ABH_Logo_mel2e1.jpg"
              width="40"
              height="30"
              className="pr-2"
              alt=""
            />
            <span className="text-white">ABH eVoting</span>
          </SourceLink>
        </Navbar>
        <Nav vertical>
          {navItems1.map(({ to, name, exact, Icon }, index) => (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className=""
                tag={NavLink}
                to={to}
                onClick={toggleClose}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
              </BSNavLink>
            </NavItem>
          ))}
          {/* 2021 elections */}
          <NavItem
            className={bem.e('nav-item')}
            onClick={handleClick('2021Elections')}
            style={isDisabledElections ? isDisabledStyle : null}
          >
            <BSNavLink className={bem.e('nav-item-collapse')}>
              <div className="d-flex">
                <MdFingerprint className={bem.e('nav-item-icon')} />
                <span className=" align-self-start">2024 Elections</span>
              </div>
              {!isDisabledElections && (
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: state.isOpen2021Elections
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              )}
            </BSNavLink>
          </NavItem>
          {!isDisabledElections && (
            <Collapse
              isOpen={state.isOpen2021Elections}
              style={{ marginLeft: 10 }}
            >
              <NavItem
                className={bem.e('nav-item')}
                onClick={handleClick('2021ElectionsExecutive')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdPeopleOutline className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Executive</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: state.isOpen2021ElectionsExecutive
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse
                isOpen={state.isOpen2021ElectionsExecutive}
                style={{ marginLeft: 10 }}
              >
                {elections2021Executives.map(
                  ({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className=""
                        tag={NavLink}
                        onClick={toggleClose}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ),
                )}
              </Collapse>
              {!isDisabledElectionsHallAssembly && (
                <>
                  <NavItem
                    className={bem.e('nav-item')}
                    onClick={handleClick('2021ElectionsHallAssembly')}
                  >
                    <BSNavLink className={bem.e('nav-item-collapse')}>
                      <div className="d-flex">
                        <MdHome className={bem.e('nav-item-icon')} />
                        <span className=" align-self-start">Hall Assembly</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e('nav-item-icon')}
                        style={{
                          padding: 0,
                          transform: state.isOpen2021ElectionsHallAssembly
                            ? 'rotate(0deg)'
                            : 'rotate(-90deg)',
                          transitionDuration: '0.3s',
                          transitionProperty: 'transform',
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse
                    isOpen={state.isOpen2021ElectionsHallAssembly}
                    style={{ marginLeft: 10 }}
                  >
                    {elections2021HallAssembly.map(
                      ({ to, name, exact, Icon }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')}>
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            className=""
                            tag={NavLink}
                            to={to}
                            onClick={toggleClose}
                            activeClassName="active"
                            exact={exact}
                          >
                            <Icon className={bem.e('nav-item-icon')} />
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      ),
                    )}
                  </Collapse>
                </>
              )}
            </Collapse>
          )}
          {/* Election Results */}
          <NavItem
            className={bem.e('nav-item')}
            onClick={handleClick('2021ElectionsResults')}
            style={isDisabledElectionResults ? isDisabledStyle : null}
          >
            <BSNavLink className={bem.e('nav-item-collapse')}>
              <div className="d-flex">
                <MdAssessment className={bem.e('nav-item-icon')} />
                <span className=" align-self-start">Election Results</span>
              </div>
              {!isDisabledElectionResults && (
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: state.isOpen2021ElectionsResults
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              )}
            </BSNavLink>
          </NavItem>
          {!isDisabledElectionResults && (
            <Collapse
              isOpen={state.isOpen2021ElectionsResults}
              style={{ marginLeft: 10 }}
            >
              {elections2021Results.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    onClick={toggleClose}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          )}

          {/* Admin Panel */}
          {user.role === 'ADMIN' && (
            <>
              <NavItem
                className={bem.e('nav-item')}
                onClick={handleClick('AdminPanel')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <RiAdminFill className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Admin Panel</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: state.isOpenAdminPanel
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse
                isOpen={state.isOpenAdminPanel}
                style={{ marginLeft: 10 }}
              >
                {adminPanel.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className=""
                      tag={NavLink}
                      to={to}
                      onClick={toggleClose}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              )
            </>
          )}

          {navItems2.map(({ to, name, exact, Icon, onClick }, index) => (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className=""
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
                onClick={() => dispatch(onClick())}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
              </BSNavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    </aside>
  );
};

export default SideBar;
