import { LOADING, LOADED } from '../types';

const DEFAULT_STATE = {};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, [action.loadType]: true };
    case LOADED:
      return { ...state, [action.loadType]: false };
    default:
      return state;
  }
};
