export const routeUrls = {
  dashboard: '/dashboard',
  signup: '/signup',
  login: '/login',
  account: '/account',
  forgotPassword: '/forgot-password',
  contactChairman:
    'https://api.whatsapp.com/send?phone=2349032598513&text=Hello%2C%0AI%20am%20__________%20of%20the%20_______%20set%2C%20________%20department%2C%20from%20_________%20floor%2C%20________%20block%2C%20ABH.%20%0AMy%20enquiries%20are%20_________________________________',
  contactDeveloper:
    'https://api.whatsapp.com/send?phone=2348139562300&text=Hello%2C%0AI%20am%20__________%20of%20the%20_______%20set%2C%20________%20department%2C%20from%20_________%20floor%2C%20________%20block%2C%20ABH.%20%0AI%20need%20help%20with%20_________________________________',
};
