import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { media } from '../data/cloudinary';
import { routeUrls } from '../config/routes';
import { login } from '../redux/actions';

const LoginForm = ({ showLogo, onLogoClick }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.loader);

  const [browniteInfo, setBrowniteInfo] = useState({
    matricNumber: '',
    password: '',
  });

  const handleInput = e => {
    e.preventDefault();
    setBrowniteInfo(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = event => {
    event.preventDefault();
    dispatch(login(browniteInfo));
  };

  return (
    <Form onSubmit={handleSubmit}>
      {showLogo && (
        <div className="text-center pb-4">
          <img
            src={media.logo}
            className="rounded"
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt="logo"
            onClick={onLogoClick}
          />
        </div>
      )}
      <FormGroup>
        <Label for="matricNumber">Matric Number</Label>
        <Input
          type="number"
          id="matricNumber"
          value={browniteInfo.matricNumber}
          onChange={handleInput}
          name="matricNumber"
          placeholder="Your matriculation number"
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          type="password"
          id="password"
          name="password"
          value={browniteInfo.password}
          onChange={handleInput}
          placeholder="Your password (> 6 characters)"
        />
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input type="checkbox" /> Remember me
        </Label>
      </FormGroup>
      <hr />
      <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={handleSubmit}
        >
          Login {loader && loader.login && <Spinner color="light" size="sm" />}
        </Button>
      </FormGroup>

      <div className="text-center pt-1">
        <h6>or</h6>
        <h6>
          <p
            onClick={() => history.push(routeUrls.signup)}
            style={{ cursor: 'pointer' }}
          >
            Signup
          </p>
        </h6>
      </div>
    </Form>
  );
};

LoginForm.propTypes = {
  showLogo: PropTypes.bool,
  onLogoClick: PropTypes.func,
};

LoginForm.defaultProps = {
  showLogo: true,
  onLogoClick: () => {},
};
export default LoginForm;
