import { SET_BROWNITE_STATS } from '../types';

const DEFAULT_STATE = {
  brownites: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_BROWNITE_STATS:
      return {
        brownites: action.brownites,
      };
    default:
      return state;
  }
};
