export const LOADING = 'LOADING';
export const LOADED = 'LOADED';

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const CLEAR_AUTH_MESSAGE = 'CLEAR_AUTH_MESSAGE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_USERS = 'SET_USERS';
export const VERIFY_USER = 'VERIFY_USER';
export const DELETE_USER = 'DELETE_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SET_POLLS = 'SET_POLLS';
export const SET_CURRENT_POLL = 'SET_CURRENT_POLL';
export const VOTE_CURRENT_POLL = 'VOTE_CURRENT_POLL';
export const SET_RESULTS = 'SET_RESULTS';

//STATS
export const SET_BROWNITE_STATS = 'SET_BROWNITE_STATS';
