import { addError } from './error';
export const handleErrors = (err, dispatch) => {
  if (err.response) {
    if (err.response.data) {
      const { errors } = err.response.data;
      dispatch(addError(errors));
    } else {
      console.log(err.response);
      dispatch(addError(err.response));
    }
  } else {
    console.log(err);
    dispatch(addError(err));
  }
};
