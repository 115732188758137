import React from 'react';
import {
  MdBook,
  MdFingerprint,
  MdPerson,
  MdPersonOutline,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from '../../utils/bemnames';
import { disabled } from '../../utils/isThisDisabled';

const navItems = [
  {
    to: 'https://www.abhpress.com',
    name: 'press',
    exact: true,
    Icon: MdBook,
    external: true,
    disabled: false,
  },
  {
    to: '/',
    name: 'E-Voting Portal',
    exact: false,
    Icon: MdFingerprint,
    external: false,
    disabled: false,
  },
  {
    to: '/signup',
    name: 'register',
    exact: false,
    Icon: MdPerson,
    external: false,
    disabled: disabled.registration,
  },
  {
    to: '/login',
    name: 'login',
    exact: false,
    Icon: MdPersonOutline,
    external: false,
    disabled: false,
  },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };
  toggleClose = () => {
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const isDisabledStyle = {
      color: 'grey',
      cursor: 'not-allowed',
      opacity: 0.5,
      textDecoration: 'none',
    };
    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          <Nav vertical>
            {navItems.map(
              ({ to, name, exact, Icon, external, disabled }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  {external ? (
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      href={to}
                      activeClassName="active"
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  ) : (
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      onClick={this.toggleClose}
                      activeClassName="active"
                      exact={exact}
                      style={disabled ? isDisabledStyle : null}
                      disabled={disabled}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  )}
                </NavItem>
              ),
            )}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
