import { SET_CURRENT_POLL, VOTE_CURRENT_POLL, SET_RESULTS } from '../types';

// // eslint-disable-next-line import/no-anonymous-default-export
// export default (state = { message: null }, action) => {
//   switch (action.type) {
//     case SET_REGISTERED_STATS:
//       return { ...state, message: action.error };
//     default:
//       return state;
//   }
// };

const DEFAULT_POLLS = {
  polls: null,
  results: null,
};
export const polls = (state = DEFAULT_POLLS, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return {
        ...state,
        results: action.results,
      };
    default:
      return state;
  }
};

const DEFAULT_CURRENT_POLL = {
  message: null,
};
export const currentPoll = (state = DEFAULT_CURRENT_POLL, action) => {
  switch (action.type) {
    case SET_CURRENT_POLL:
      return {
        ...state,
        ...action.poll,
      };
    case VOTE_CURRENT_POLL:
      return {
        ...state,
        message: action.message,
        poll: {
          ...state.poll,
          ...action.poll,
        },
      };
    default:
      return state;
  }
};
