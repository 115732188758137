import moment from 'moment';

export const notificationsData = user => [
  {
    id: 1,
    avatar: user.photoUrl,
    message: 'Update your room number to complete your profile.',
    date: moment(user.createdAt).fromNow(),
  },
];
