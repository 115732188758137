import { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Row, Col, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getColor } from '../../utils/colors';
import { getBrowniteStats } from '../../redux/actions';

const PollingMonitor = () => {
  const brownites = useSelector(state => state.stats.brownites);
  const {
    mbbs2019,
    mbbs2021,
    mbbs2023,
    bds2019,
    bds2021,
    bds2023,
    mls2021,
    mls2022,
    mls2023,
    physiotherapy2021,
    physiotherapy2022,
    physiotherapy2023
  } = brownites;
  const dispatch = useDispatch();
  const genPieDataMBBS = checkNull => {
    if (checkNull) {
      return mbbs2019.total === 0 &&
      mbbs2021.total === 0 &&
      mbbs2023.total
        ? 'null'
        : 'not null';
    }
    return {
      datasets: [
        {
          data: [mbbs2019.total, mbbs2021.total, mbbs2023.total],
          backgroundColor: [
            getColor('primary'),
            getColor('secondary'),
            getColor('success'),
            getColor('info'),
            getColor('danger'),
          ],
          label: 'MBBS',
        },
      ],
      labels: ['SET 2019', 'SET 2021', 'SET 2023'],
    };
  };
  const genPieDataBDS = checkNull => {
    if (checkNull) {
      return bds2019.total === 0 && bds2021.total === 0 && bds2023.total === 0
        ? 'null'
        : 'not null';
    }
    return {
      datasets: [
        {
          data: [bds2019.total, bds2021.total, bds2023.total],
          backgroundColor: [
            getColor('primary'),
            getColor('secondary'),
            getColor('success'),
            getColor('info'),
            getColor('danger'),
          ],
          label: 'BDS',
        },
      ],
      labels: ['SET 2019', 'SET 2021', 'SET 2023'],
    };
  };
  const genPieDataMLS = checkNull => {
    if (checkNull) {
      return mls2021.total === 0 && mls2022.total === 0 && mls2023.total === 0 ? 'null' : 'not null';
    }
    return {
      datasets: [
        {
          data: [mls2021.total, mls2022.total, mls2023.total],
          backgroundColor: [
            getColor('primary'),
            getColor('secondary'),
            getColor('success'),
            getColor('info'),
            getColor('danger'),
          ],
          label: 'BMLS',
        },
      ],
      labels: ['SET 2021', 'SET 2022', 'SET 2023'],
    };
  };

  const genPieDataPhysiotherapy = checkNull => {
    if (checkNull) {
      return physiotherapy2021.total === 0 &&
        physiotherapy2022.total === 0 &&
        physiotherapy2023.total
        ? 'null'
        : 'not null';
    }
    return {
      datasets: [
        {
          data: [
            physiotherapy2021.total,
            physiotherapy2022.total,
            physiotherapy2023.total,
          ],
          backgroundColor: [
            getColor('primary'),
            getColor('secondary'),
            getColor('success'),
            getColor('info'),
            getColor('danger'),
          ],
          label: 'Physiotherapy',
        },
      ],
      labels: ['SET 2021', 'SET 2022', 'SET 2023'],
    };
  };
  const isFetched = Object.keys(brownites).length > 0;
  useEffect(() => {
    dispatch(getBrowniteStats());
  }, [dispatch]);
  return (
    <div>
      <Row>
        <Col xl={6} lg={12} md={12}>
          <Card className="m-2 m-sm-3 m-md-4">
            <CardHeader>Registered voters [Physiotherapy]</CardHeader>
            <CardBody>
              {isFetched ? (
                <>
                  {genPieDataPhysiotherapy('checkNull') === 'null' ? (
                    <h5>NONE REGISTERED</h5>
                  ) : (
                    <Doughnut data={genPieDataPhysiotherapy()} />
                  )}
                </>
              ) : (
                <Spinner />
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Card className="m-2 m-sm-3 m-md-4">
            <CardHeader>Registered voters [MBBS]</CardHeader>

            <CardBody>
              {isFetched ? (
                <>
                  {genPieDataMBBS('checkNull') === 'null' ? (
                    <h5>NONE REGISTERED</h5>
                  ) : (
                    <Doughnut data={genPieDataMBBS()} />
                  )}
                </>
              ) : (
                <Spinner />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={12} md={12}>
          <Card className="m-2 m-sm-3 m-md-4">
            <CardHeader>Registered voters [BDS]</CardHeader>

            <CardBody>
              {isFetched ? (
                <>
                  {genPieDataBDS('checkNull') === 'null' ? (
                    <h5>NONE REGISTERED</h5>
                  ) : (
                    <Doughnut data={genPieDataBDS()} />
                  )}
                </>
              ) : (
                <Spinner />
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Card className="m-2 m-sm-3 m-md-4">
            <CardHeader>Registered voters [BMLS]</CardHeader>

            <CardBody>
              {isFetched ? (
                <>
                  {genPieDataMLS('checkNull') === 'null' ? (
                    <h5>NONE REGISTERED</h5>
                  ) : (
                    <Doughnut data={genPieDataMLS()} />
                  )}
                </>
              ) : (
                <Spinner />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default PollingMonitor;
