import { combineReducers } from 'redux';

import error from './error';
import auth from './auth';
import { polls, currentPoll } from './polls';
import stats from './stats';
import loader from './loader';

export default combineReducers({
  error,
  auth,
  polls,
  currentPoll,
  stats,
  loader,
});
