import axios from 'axios';

const host =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9000'
    : 'https://abhelection-server.vercel.app';
export const setToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};
export const call = (method, path, data) => {
  return axios[method](`${host}/${path}`, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};
//new comment
export default { call, setToken };
