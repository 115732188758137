import React from 'react';

const SourceLink = props => {
  /* eslint-disable jsx-a11y/anchor-has-content */
  const { protocol, host } = window.location;
  return (
    <a
      href={props.href ? props.href : `${protocol}//${host}`}
      rel="noopener noreferrer"
      {...props}
    />
  );
};

export default SourceLink;
