import { MdFingerprint } from 'react-icons/md';

import { FaWhatsapp, FaNewspaper } from 'react-icons/fa';
import { routeUrls } from '../../config/routes';

export const iconWidgetsData = [
  {
    bgColor: 'primary',
    icon: FaNewspaper,
    title: 'Press',
    subtitle: 'Follow Updates',
    onClick: () => {
      window.location.href = 'https://abhpress.com';
    },
  },
  {
    bgColor: 'primary',
    icon: MdFingerprint,
    title: 'Brownite?',
    subtitle: 'Get registered',
    onClick: () => {
      window.location.href = '/signup';
    },
  },
  {
    bgColor: 'success',
    icon: FaWhatsapp,
    title: 'Enquiries?',
    subtitle: 'Contact Chairman',
    onClick: () => {
      window.location.href = routeUrls.contactChairman;
    },
  },
  {
    bgColor: 'success',
    icon: FaWhatsapp,
    title: 'Need help?',
    subtitle: 'Contact Developer',
    onClick: () => {
      window.location.href = routeUrls.contactDeveloper;
    },
  },
];

export const numberWidgetsData = [
  { color: 'primary' },
  { color: 'secondary' },
  { color: 'success' },
  { color: 'info' },
  { color: 'warning' },
  { color: 'danger' },
  { color: 'light' },
  { color: 'dark' },
];
