import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { store } from '../redux';
import { getColor } from './colors';

// Notification Component
const Notification = ({
  successMessage,
  errorMessage,
  removeSuccessCallback,
  removeErrorCallback,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    //Clear success message after 2 seconds
    if (successMessage && successMessage !== null) {
      setTimeout(() => {
        dispatch(removeSuccessCallback());
      }, 10000);
    }
    // Clear error message after 2 seconds
    if (errorMessage && errorMessage !== null) {
      setTimeout(() => {
        dispatch(removeErrorCallback());
      }, 10000);
    }
  }, [
    dispatch,
    errorMessage,
    removeErrorCallback,
    removeSuccessCallback,
    successMessage,
  ]);

  return (
    <>
      {errorMessage != null && (
        <p style={{ color: getColor('danger') }}> {errorMessage}</p>
      )}
      {successMessage != null && (
        <p style={{ color: getColor('success') }}> {successMessage}</p>
      )}
    </>
  );
};

export default Notification;
