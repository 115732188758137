import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { media } from '../data/cloudinary';
import { routeUrls } from '../config/routes';
import { resetPassword } from '../redux/actions';

const ResetPassword = ({ showLogo, onLogoClick, resetId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.loader);
  const [browniteInfo, setBrowniteInfo] = useState({
    newPassword: '',
  });
  const handleInput = e => {
    setBrowniteInfo(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(resetPassword({ ...browniteInfo, _id: resetId }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      {showLogo && (
        <div className="text-center pb-4">
          <img
            src={media.logo}
            className="rounded"
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt="logo"
            onClick={onLogoClick}
          />
        </div>
      )}
      <FormGroup>
        <Label for="matricNumber">Your New Password</Label>
        <Input
          type="password"
          id="newPassword"
          value={browniteInfo.newPassword}
          onChange={handleInput}
          name="newPassword"
          placeholder="Your new password"
        />
      </FormGroup>
      <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={handleSubmit}
        >
          Reset Password{' '}
          {loader && loader.resetPassword && (
            <Spinner color="light" size="sm" />
          )}
        </Button>
      </FormGroup>
      <div className="text-center pt-1">
        <h6>or</h6>
        <h6>
          <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
            Go back
          </p>
        </h6>
      </div>
    </Form>
  );
};

ResetPassword.propTypes = {
  showLogo: PropTypes.bool,
  onLogoClick: PropTypes.func,
};

ResetPassword.defaultProps = {
  showLogo: true,
  onLogoClick: () => {},
};
export default ResetPassword;
