import { MdClearAll } from 'react-icons/md';

import { Button, Nav, Navbar, NavItem } from 'reactstrap';
import SourceLink from '../SourceLink';
import bn from '../../utils/bemnames';

const bem = bn.create('header');

const Header = () => {
  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  return (
    <Navbar light expand className={`${bem.b('bg-white')} mb-0`}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton} className="ml-0">
          <MdClearAll size={25} />
        </Button>
        <NavItem>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={
                'https://res.cloudinary.com/dcjxlbq4z/image/upload/v1612836816/abhelections/ABH_Logo_mel2e1.jpg'
              }
              width="50"
              height="50"
              className="pr-2"
              alt=""
              style={{ marginLeft: 10 }}
            />
          </SourceLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
