import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from '../SourceLink';

const Footer = () => {
  return (
    <Navbar className="justify-content-center justify-content-md-between">
      <Nav navbar className="mx-2 mx-md-3 mt-2 mt-md-4">
        <NavItem>
          <div className="d-flex align-items-center">
            <img
              style={{
                marginRight: '1rem',
                maxwidth: '40px',
                height: 40,
                width: 40,
              }}
              src="https://res.cloudinary.com/dcjxlbq4z/image/upload/v1612836816/abhelections/ABH_Logo_mel2e1.jpg"
              alt="abh-evoting"
            />
            <p className="mbr-fonts-style mbr-bold text-logo-footer mb-0 display-5">
              ABH eVoting
            </p>
          </div>
        </NavItem>
      </Nav>
      <Nav navbar>
        <NavItem>
          &#169; {new Date().getFullYear()} ABH eVoting. All rights reserved.
        </NavItem>
      </Nav>
      <Nav navbar>
        <NavItem>
          Developed by{' '}
          <SourceLink
            href="https://linktr.ee/ikmuogbo"
            style={{ color: '#d9652a' }}
          >
            Ifeanyi Muogbo
          </SourceLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
