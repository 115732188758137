import loader from './loader.svg';
const Loader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 999999,
        transform: 'translate(-50%, 0)',
      }}
    >
      <img src={loader} alt="Loading..." />
    </div>
  );
};
export default Loader;
