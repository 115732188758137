import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { MdFingerprint } from 'react-icons/md';

const HeroSection = () => {
  return (
    <section className="header4 cid-s2V9SnoqGp" id="header4-1">
      <div
        className="mbr-overlay"
        style={{
          backgroundColor: 'rgb(19, 40, 125)',
          opacity: 0.8,
        }}
      ></div>
      <Container className="align-left">
        <Row>
          <Col md={12} lg={12}>
            <div className="wrap d-flex flex-column">
              <h1 className="mbr-section-title mbr-fonts-style mbr-bold align-left w-100 display-1">
                Welcome to <div>ABH's eVoting Portal</div>
              </h1>
              <p className="mbr-text align-left mbr-fonts-style mt-4 display-4">
                The electoral ban for the 2024 ABH general elections has been
                lifted and electioneering activities have begun. Brownites are
                advised to register a voters account here before the
                registration deadline elapses.&nbsp;
              </p>
              <div className="buttons-wrap d-flex mt-4 w-100 justify-content-start">
                <div
                  className="mbr-section-btn align-center"
                  style={{
                    display: 'flex',
                    marginLeft: '-.25rem',
                    marginRight: '-.25rem',
                    fontSize: 0,
                    textAlign: 'center',
                  }}
                >
                  <a
                    className="btn btn-lg btn-warning display-4"
                    href="https://drive.google.com/file/d/1O3Rg02uWIjKGGDEQ6qHdiCFPUls-uHuB/view"
                    target="_blank"
                    style={{
                      borderRadius: 100,
                      fontWeight: '700',
                      background: '#eff2f7',
                      borderColor: '#eff2f7',
                      color: '#4d6a9a',
                      padding: '20px 47px',
                      fontSize: '0.9375rem',
                      zIndex: '9999'
                    }}
                  >
                    Guidelines
                  </a>
                </div>
                <div className="video-wrap d-flex align-items-center">
                  <div
                    className="mbr-media show-modal align-center d-flex align-items-center"
                    data-modal=".modalWindow"
                  >
                    <div
                      className="icon-wrap"
                      onClick={() => {
                        window.location.href = '/signup';
                      }}
                    >
                      <MdFingerprint
                        style={{ height: '3.5rem', width: '3.5rem' }}
                      />
                      <span className="line-video-animation line-video-1"></span>
                      <br />
                      <span className="line-video-animation line-video-2"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className="skew"
        style={{
          backgroundImage:
            'url(https://res.cloudinary.com/dcjxlbq4z/image/upload/v1613257064/abhelections/bg-skew_bwxu8m.png)',
          backgroundPosition: 'center left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          overflow: 'hidden',
          transition:
            'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s',
          marginTop: '-140px',
          marginBottom: 0,
          zIndex: 8,
          position: 'absolute',
          display: 'block',
          width: '100%',
          bottom: 0,
        }}
      >
        <div
          className="inner"
          style={{
            minHeight: 150,
            alignItems: 'center',
            display: 'flex',
            marginRight: 'auto',
            marginLeft: 'auto',
            position: 'relative',
          }}
        ></div>
      </div>
    </section>
  );
};

export default HeroSection;
