import { Card, Col, Row } from 'reactstrap';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LoginForm from '../components/LoginForm';
import SignupForm from '../components/SignupForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import ResetPasswordForm from '../components/ResetPasswordForm';
import Notification from '../utils/notify';

import { removeError, removeAuthSuccessMessage } from '../redux/actions';
import { routeUrls } from '../config/routes';
import { disabled } from '../utils/isThisDisabled';

const AuthPage = ({ authState }) => {
  const history = useHistory();
  const { resetId } = useParams();
  const error = useSelector(state => state.error.message);
  const message = useSelector(state => state.auth.message);

  const handleLogoClick = () => {
    history.push('/');
  };
  return (
    <Row
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {authState === 'SIGNUP' && disabled.registration ? null : (
        <Col md={8} lg={6} className="mt-2 mt-md-4">
          <Card body>
            {authState === 'LOGIN' ? (
              <LoginForm authState={authState} onLogoClick={handleLogoClick} />
            ) : authState === 'SIGNUP' ? (
              <SignupForm authState={authState} onLogoClick={handleLogoClick} />
            ) : authState === 'FORGOT_PASSWORD' ? (
              <ForgotPasswordForm
                authState={authState}
                onLogoClick={handleLogoClick}
              />
            ) : authState === 'RESET_PASSWORD' ? (
              <ResetPasswordForm
                resetId={resetId}
                authState={authState}
                onLogoClick={handleLogoClick}
              />
            ) : null}
            {authState !== 'RESET_PASSWORD' && authState !== 'FORGOT_PASSWORD' && (
              <Link to={routeUrls.forgotPassword} style={{ color: '#d9652a' }}>
                Forgot password?
              </Link>
            )}
          </Card>
          <div className="d-flex justify-content-center p-2">
            <Notification
              errorMessage={error}
              removeErrorCallback={removeError}
              successMessage={message}
              removeSuccessCallback={removeAuthSuccessMessage}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default AuthPage;
