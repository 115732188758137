import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routeUrls } from '../../config/routes';

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const state = useSelector(state => state);
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={props =>
        state.auth.isAuthenticated &&
        state.auth.user &&
        state.auth.user.role === 'ADMIN' ? (
          <Component {...props} />
        ) : state.auth.isAuthenticated ? (
          <Redirect
            to={{
              pathname: routeUrls.dashboard,
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: routeUrls.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AdminProtectedRoute;
