import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const DEFAULT_STATE = {
  error: { message: null },
  user: {},
};

const developmentServer = process.env.NODE_ENV === 'development';
const composeEnhancers =
  developmentServer &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // other store enhancers if any
);
export const store = createStore(rootReducer, DEFAULT_STATE, enhancer);
