import { SET_BROWNITE_STATS } from '../types.js';
import { addError, removeError } from './error';
import api from '../services/api';
import { handleErrors } from './utils';

export const setBrowniteStats = brownites => ({
  type: SET_BROWNITE_STATS,
  brownites,
});

export const getBrowniteStats = () => {
  return async dispatch => {
    try {
      const {
        data: { stats },
      } = await api.call('get', 'polls/stats');
      dispatch(setBrowniteStats(stats));
      dispatch(removeError());
    } catch (err) {
      handleErrors(err, dispatch);
    }
  };
};
