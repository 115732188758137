import { LOADING, LOADED } from '../types';

export const setLoading = loadType => ({
  type: LOADING,
  loadType,
});
export const setLoaded = loadType => ({
  type: LOADED,
  loadType,
});
