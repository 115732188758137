import { Row, Col } from 'reactstrap';

import { IconWidget } from '../../components/Widget';
import { iconWidgetsData } from './widgetData';

const CtaSection = () => {
  return (
    <div className="px-2 px-sm-3 px-md-5 px-lg-5">
      <Row>
        {iconWidgetsData.map(
          (
            { bgColor, icon, title, subtitle, onClick, ...restProps },
            index,
          ) => (
            <Col key={index} lg={3} md={3} sm={12} xs={12} className="mb-3">
              <IconWidget
                bgColor={bgColor}
                icon={icon}
                title={title}
                subtitle={subtitle}
                onClick={onClick}
                {...restProps}
              />
            </Col>
          ),
        )}
      </Row>
    </div>
  );
};
export default CtaSection;
