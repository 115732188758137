import { Children, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ReactGA from 'react-ga';

const hasGAId = !!process.env.REACT_APP_GOOGLE_ANALYTICS;

if (hasGAId) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

const GAListener = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    if (hasGAId) {
      sendPageView(history.location);
      history.listen(sendPageView);
    }
  }, [history]);
  const sendPageView = location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  };

  return children;
};

export default GAListener;
