import {
  SET_POLLS,
  SET_CURRENT_POLL,
  VOTE_CURRENT_POLL,
  SET_RESULTS,
} from '../types.js';
import { addError, removeError } from './error';
import api from '../services/api';
import { handleErrors } from './utils';

export const setPolls = polls => ({
  type: SET_POLLS,
  polls,
});

export const setCurrentPoll = poll => ({
  type: SET_CURRENT_POLL,
  poll,
});
export const voteCurrentPoll = (poll, message) => ({
  type: VOTE_CURRENT_POLL,
  poll,
  message,
});

// export const getPolls = () => {
//   return async dispatch => {
//     try {
//       const { data } = await api.call('get', 'polls');
//       dispatch(setPolls(data));
//       dispatch(removeError());
//     } catch (err) {
//       handleErrors(err, dispatch)
//     }
//   };
// };

// export const getUserPolls = () => {
//   return async dispatch => {
//     try {
//       const polls = await api.call('get', 'polls/user');
//       dispatch(setPolls(polls));
//       dispatch(removeError());
//     } catch (err) {
//       handleErrors(err, dispatch)
//     }
//   };
// };

// export const createPoll = data => {
//   return async dispatch => {
//     try {
//       const { data } = await api.call('post', 'polls', data);
//       dispatch(setCurrentPoll(data));
//       dispatch(removeError());
//     } catch (err) {
//       handleErrors(err, dispatch)
//     }
//   };
// };

export const getCurrentPollByQuestion = question => {
  return async dispatch => {
    try {
      const { data } = await api.call('post', 'polls/question', question);
      dispatch(setCurrentPoll(data));
      dispatch(removeError());
    } catch (err) {
      handleErrors(err, dispatch);
    }
  };
};

export const vote = (id, data) => {
  return async dispatch => {
    try {
      const {
        data: { poll, message },
      } = await api.call('post', `polls/${id}`, data);
      dispatch(voteCurrentPoll(poll, message));
      dispatch(removeError());
    } catch (err) {
      handleErrors(err, dispatch);
    }
  };
};

export const setResults = () => {
  return async dispatch => {
    try {
      const { data } = await api.call('get', `polls/results`);
      dispatch({ type: SET_RESULTS, results: data });
      dispatch(removeError());
    } catch (err) {
      handleErrors(err, dispatch);
    }
  };
};
