import { useState } from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Avatar from '../Avatar';
import { UserCard } from '../Card';
import Notifications from '../Notifications';
import { notificationsData } from '../../data/header';
import withBadge from '../../hocs/withBadge';
import bn from '../../utils/bemnames';
import SourceLink from '../SourceLink';
import { routeUrls } from '../../config/routes';
import { logout } from '../../redux/actions';
import { media } from '../../data/cloudinary';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>{notificationsData.length}</small>,
})(MdNotificationsActive);

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const [isOpenNotificationPopover, setIsOpenNotificationPopover] = useState(
    false,
  );
  const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(
    user.roomNumber && user.roomNumber > 0,
  );
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);

  const toggleNotificationPopover = () => {
    setIsOpenNotificationPopover(!isOpenNotificationPopover);

    if (!isNotificationConfirmed) {
      setIsNotificationConfirmed(true);
    }
  };

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton} className="ml-0">
          <MdClearAll size={25} />
        </Button>
      </Nav>
      <Nav navbar>
        <NavItem>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={media.logo}
              width="50"
              height="50"
              className="pr-2"
              alt=""
              style={{ marginLeft: 10 }}
            />
          </SourceLink>
        </NavItem>
      </Nav>
      <Nav navbar className={bem.e('nav-right')}>
        <NavItem className="d-inline-flex">
          <NavLink id="Popover1" className="position-relative">
            {isNotificationConfirmed ? (
              <MdNotificationsNone
                size={25}
                className="text-secondary can-click"
                onClick={toggleNotificationPopover}
              />
            ) : (
              <MdNotificationsActiveWithBadge
                size={25}
                className="text-secondary can-click animated swing infinite"
                onClick={toggleNotificationPopover}
              />
            )}
          </NavLink>
          <Popover
            placement="bottom"
            isOpen={isOpenNotificationPopover}
            toggle={toggleNotificationPopover}
            target="Popover1"
          >
            <PopoverBody>
              <Notifications notificationsData={notificationsData(user)} />
            </PopoverBody>
          </Popover>
        </NavItem>

        <NavItem>
          <NavLink id="Popover2">
            <Avatar
              onClick={toggleUserCardPopover}
              className="can-click"
              src={user.photoUrl}
            />
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light">
              <UserCard
                title={user.fullName}
                subtitle={user.email}
                text={`Last updated ${moment(user.lastUpdated).fromNow()}`}
                className="border-light"
                avatar={user.photoUrl}
              >
                <ListGroup flush>
                  <ListGroupItem
                    tag="button"
                    action
                    className="border-light"
                    onClick={() => {
                      history.push(routeUrls.account);
                      setIsOpenUserCardPopover(false);
                    }}
                  >
                    <MdPersonPin /> Edit Profile
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    action
                    className="border-light"
                    onClick={() => {
                      window.location.href = routeUrls.contactDeveloper;
                      setIsOpenUserCardPopover(false);
                    }}
                  >
                    <MdHelp /> Get Help
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    action
                    className="border-light"
                    onClick={() => {
                      dispatch(logout());
                      setIsOpenUserCardPopover(false);
                    }}
                  >
                    <MdExitToApp /> Signout
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
