import { addError, removeError } from './error';
import { setLoaded, setLoading } from './loader';
import {
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  CLEAR_AUTH_MESSAGE,
  CHANGE_PASSWORD,
  SET_USERS,
  VERIFY_USER,
  DELETE_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../types';
import api from '../services/api';
import { handleErrors } from './utils';

export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  user,
});
export const updateCurrentUser = (user, message) => ({
  type: UPDATE_CURRENT_USER,
  user,
  message,
});

export const removeAuthSuccessMessage = () => ({
  type: CLEAR_AUTH_MESSAGE,
});

export const setToken = token => {
  api.setToken(token);
};

export const logout = () => {
  return dispatch => {
    localStorage.clear();
    window.location.href = `${window.location.protocol}//${window.location.host}/login`;
    api.setToken(null);
    dispatch(setCurrentUser({}));
    dispatch(removeError());
  };
};

export const register = data => {
  return async dispatch => {
    try {
      dispatch(setLoading('register'));
      const {
        data: { token, _id },
      } = await api.call('post', 'users/register', data);
      localStorage.setItem('jwtToken', token);
      const {
        data: { brownite },
      } = await api.call('get', `users/${_id}`);
      api.setToken(token);
      dispatch(setCurrentUser(brownite));
      window.location.href = `${window.location.protocol}//${window.location.host}/dashboard`;
      dispatch(removeError());
      dispatch(setLoaded('register'));
    } catch (err) {
      dispatch(setLoaded('register'));
      handleErrors(err, dispatch);
    }
  };
};

export const login = data => {
  return async dispatch => {
    try {
      dispatch(setLoading('login'));
      const {
        data: { token, _id },
      } = await api.call('post', 'users/login', data);
      localStorage.setItem('jwtToken', token);
      const {
        data: { brownite },
      } = await api.call('get', `users/${_id}`);
      api.setToken(token);
      dispatch(setCurrentUser(brownite));
      window.location.href = `${window.location.protocol}//${window.location.host}/dashboard`;
      dispatch(removeError());
      dispatch(setLoaded('login'));
    } catch (err) {
      dispatch(setLoaded('login'));
      handleErrors(err, dispatch);
    }
  };
};

export const editProfile = (data, id) => {
  return async dispatch => {
    try {
      dispatch(setLoading('editProfile'));
      const {
        data: { message, brownite, token },
      } = await api.call('put', `users/${id}`, data);
      dispatch(updateCurrentUser(brownite, message));
      //Update token in localStorage (We store userData in token)
      // TODO - Encrypt token in localStorage
      localStorage.setItem('jwtToken', token);
      api.setToken(token);
      dispatch(removeError());
      dispatch(setLoaded('editProfile'));
    } catch (err) {
      dispatch(setLoaded('editProfile'));
      handleErrors(err, dispatch);
    }
  };
};

export const changePassword = (data, id) => {
  return async dispatch => {
    try {
      dispatch(setLoading('changePassword'));
      const {
        data: { message },
      } = await api.call('put', `users/passwordchange/${id}`, data);
      dispatch({ type: CHANGE_PASSWORD, message });
      dispatch(removeError());
      dispatch(setLoaded('changePassword'));
    } catch (err) {
      dispatch(setLoaded('changePassword'));
      handleErrors(err, dispatch);
    }
  };
};

////////////// ADMIN
// Fetch all users in DB
export const setUsers = () => {
  return async dispatch => {
    try {
      dispatch(setLoading('setUsers'));
      const {
        data: { Brownites },
      } = await api.call('get', `users`);
      dispatch({ type: SET_USERS, users: Brownites });
      dispatch(removeError());
      dispatch(setLoaded('setUsers'));
    } catch (err) {
      dispatch(setLoaded('setUsers'));
      handleErrors(err, dispatch);
    }
  };
};
// Verify User and refetch all
export const verifyUser = (id, data) => {
  return async dispatch => {
    try {
      dispatch(setLoading('verifyUser'));
      const {
        data: { brownite, message },
      } = await api.call('post', `users/${id}`, data);
      dispatch({ type: VERIFY_USER, message, brownite });
      dispatch(removeError());
      dispatch(setLoaded('verifyUser'));
    } catch (err) {
      dispatch(setLoaded('verifyUser'));
      handleErrors(err, dispatch);
    }
  };
};

// Delete User and refetch all
export const deleteUser = id => {
  return async dispatch => {
    try {
      dispatch(setLoading('deleteUser'));
      const {
        data: { message },
      } = await api.call('delete', `users/${id}`);
      dispatch({ type: DELETE_USER, message });
      dispatch(removeError());
      dispatch(setLoaded('deleteUser'));
    } catch (err) {
      dispatch(setLoaded('deleteUser'));
      handleErrors(err, dispatch);
    }
  };
};

// Forgot Password
export const forgotPassword = data => {
  return async dispatch => {
    try {
      dispatch(setLoading('forgotPassword'));
      const {
        data: { message },
      } = await api.call('post', `users/forgot-password`, data);
      dispatch({ type: 'FORGOT_PASSWORD', message });
      dispatch(removeError());
      dispatch(setLoaded('forgotPassword'));
    } catch (err) {
      dispatch(setLoaded('forgotPassword'));
      handleErrors(err, dispatch);
    }
  };
};

// Reset Password
export const resetPassword = data => {
  return async dispatch => {
    try {
      dispatch(setLoading('resetPassword'));
      const {
        data: { message },
      } = await api.call('post', `users/reset-password`, data);
      dispatch({ type: 'RESET_PASSWORD', message });
      dispatch(removeError());
      dispatch(setLoaded('resetPassword'));
    } catch (err) {
      dispatch(setLoaded('resetPassword'));
      handleErrors(err, dispatch);
    }
  };
};
