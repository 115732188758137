import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { media } from '../data/cloudinary';
import { routeUrls } from '../config/routes';
import { register } from '../redux/actions';

const SignupForm = ({ showLogo, onLogoClick }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.loader);
  const [browniteInfo, setBrowniteInfo] = useState({
    fullName: '',
    matricNumber: '',
    email: '',
    department: '',
    set: '',
    floor: '',
    password: '',
    password2: '',
  });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const handleInput = e => {
    setBrowniteInfo(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const toggleAgreeTerms = e => setAgreeTerms(e.target.checked);

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(register(browniteInfo));
  };

  return (
    <Form onSubmit={handleSubmit}>
      {showLogo && (
        <div className="text-center pb-4">
          <img
            src={media.logo}
            className="rounded"
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt="logo"
            onClick={onLogoClick}
          />
        </div>
      )}

      <FormGroup>
        <Label for="fullName">Full Name</Label>
        <Input
          type="text"
          name="fullName"
          id="fullName"
          value={browniteInfo.fullName}
          onChange={handleInput}
          placeholder="LAST NAME, FirstName MiddleName"
        />
      </FormGroup>
      <FormGroup>
        <Label for="matricNumber">Matric Number</Label>
        <Input
          type="number"
          id="matricNumber"
          value={browniteInfo.matricNumber}
          onChange={handleInput}
          name="matricNumber"
          placeholder="Your matriculation number"
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          id="email"
          value={browniteInfo.email}
          onChange={handleInput}
          name="email"
          placeholder="Your email address"
        />
      </FormGroup>
      <FormGroup>
        <Label for="department">Department</Label>
        <Input
          type="select"
          name="department"
          id="department"
          value={browniteInfo.department}
          onChange={handleInput}
        >
          <option value={null}>Select Your Department</option>
          <option value="Physiotherapy">Physiotherapy</option>
          <option value="MBBS">MBBS</option>
          <option value="BDS">BDS</option>
          <option value="MLS">BMLS</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="set">Set/Level</Label>
        <Input
          type="select"
          name="set"
          id="set"
          value={browniteInfo.set}
          onChange={handleInput}
        >
          <option value={null}>Select Your Set</option>
          <option value={2019}>2019</option>
          <option value={2021}>2021</option>
          <option value={2022}>2022</option>
          <option value={2023}>2023</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="floor">Block/Floor</Label>

        <Input
          type="select"
          name="floor"
          id="floor"
          value={browniteInfo.floor}
          onChange={handleInput}
        >
          <option value={null}>Select Your Block/Floor</option>
          <option value="Block A, Ground Floor">Block A, Ground Floor</option>
          <option value="Block A, 1st Floor">Block A, 1st Floor</option>
          <option value="Block A, 2nd Floor">Block A, 2nd Floor</option>
          <option value="Block A, 3rd Floor">Block A, 3rd Floor</option>
          <option value="Block B, Ground Floor">Block B, Ground Floor</option>
          <option value="Block B, 1st Floor">Block B, 1st Floor</option>
          <option value="Block B, 2nd Floor">Block B, 2nd Floor</option>
          <option value="Block B, 3rd Floor">Block B, 3rd Floor</option>
          <option value="Block C, Ground Floor">Block C, Ground Floor</option>
          <option value="Block C, 1st Floor">Block C, 1st Floor</option>
          <option value="Block C, 2nd Floor">Block C, 2nd Floor</option>
          <option value="Block C, 3rd Floor">Block C, 3rd Floor</option>
          <option value="Block D, Ground Floor">Block D, Ground Floor</option>
          <option value="Block D, 1st Floor">Block D, 1st Floor</option>
          <option value="Block D, 2nd Floor">Block D, 2nd Floor</option>
          <option value="Block D, 3rd Floor">Block D, 3rd Floor</option>
          <option value="Block E, Ground Floor">Block E, Ground Floor</option>
          <option value="Block E, 1st Floor">Block E, 1st Floor</option>
          <option value="Block E, 2nd Floor">Block E, 2nd Floor</option>
          <option value="Block E, 3rd Floor">Block E, 3rd Floor</option>
          <option value="Block F, Ground Floor">Block F, Ground Floor</option>
          <option value="Block G Left, Ground Floor">
            Block G Left, Ground Floor
          </option>
          <option value="Block G Left, 1st Floor">
            Block G Left, 1st Floor
          </option>
          <option value="Block G Left, 2nd Floor">
            Block G Left, 2nd Floor
          </option>
          <option value="Block G Left, 3rd Floor">
            Block G Left, 3rd Floor
          </option>
          <option value="Block G Right, Ground Floor">
            Block G Right, Ground Floor
          </option>
          <option value="Block G Right, 1st Floor">
            Block G Right, 1st Floor
          </option>
          <option value="Block G Right, 2nd Floor">
            Block G Right, 2nd Floor
          </option>
          <option value="Block G Right, 3rd Floor">
            Block G Right, 3rd Floor
          </option>
          <option value="OffCampus / ABH BQs">OffCampus / ABH BQs</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          type="password"
          id="password"
          name="password"
          value={browniteInfo.password}
          onChange={handleInput}
          placeholder="Your password (> 6 characters)"
        />
      </FormGroup>

      <FormGroup>
        <Label for="password2">Confirm password</Label>
        <Input
          type="password"
          id="password2"
          name="password2"
          value={browniteInfo.password2}
          onChange={handleInput}
          placeholder="Confirm password"
        />
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            value={agreeTerms}
            onChange={toggleAgreeTerms}
          />{' '}
          I agree to the terms and policy
        </Label>
      </FormGroup>
      <hr />
      <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={handleSubmit}
        >
          Signup{' '}
          {loader && loader.register && <Spinner color="light" size="sm" />}
        </Button>
      </FormGroup>
      <div className="text-center pt-1">
        <h6>or</h6>
        <h6>
          <p
            onClick={() => history.push(routeUrls.login)}
            style={{ cursor: 'pointer' }}
          >
            Login
          </p>
        </h6>
      </div>
    </Form>
  );
};

SignupForm.propTypes = {
  showLogo: PropTypes.bool,
  onLogoClick: PropTypes.func,
};

SignupForm.defaultProps = {
  showLogo: true,
  onLogoClick: () => {},
};
export default SignupForm;
