import { Content, Footer, NonAuthHeader, NonAuthSidebar } from '../Layout';

const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app bg-light" {...restProps}>
    <NonAuthSidebar />
    <Content fluid>
      <NonAuthHeader />

      {children}
      <Footer />
    </Content>
  </main>
);

export default EmptyLayout;
