import { lazy, Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import componentQueries from 'react-component-queries';
import decode from 'jwt-decode';

import GAListener from './components/GAListener';
import {
  EmptyLayout,
  LayoutUnprotectedRoute,
  AdminProtectedRoute,
  AuthProtectedRoute,
  MainLayout,
} from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage/index';
import { store } from './redux';
import { setCurrentUser, addError } from './redux/actions';
import { setToken, call } from './redux/services/api';
import StyleWrap from './styles';
const AccountPage = lazy(() => import('./pages/AccountPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const PollPage = lazy(() => import('./pages/PollPage'));
const ResultsPage = lazy(() => import('./pages/ResultsPage'));

const rehydrateData = async () => {
  try {
    const jwtTokenDecoded = decode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(jwtTokenDecoded));
    const { _id } = jwtTokenDecoded;
    const {
      data: { brownite },
    } = await call('get', `users/${_id}`);
    if (brownite === null || brownite === undefined) {
      store.dispatch(setCurrentUser({}));
      delete localStorage.jwtToken;
    } else {
      store.dispatch(setCurrentUser(brownite));
    }
  } catch (err) {
    delete localStorage.jwtToken;
    store.dispatch(setCurrentUser({}));
    store.dispatch(addError(err));
  }
};

if (localStorage.jwtToken) {
  setToken(localStorage.jwtToken);
  rehydrateData();
}
const App = ({ breakpoint }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* <GAListener> */}
        <StyleWrap>
          <Switch>
            <LayoutUnprotectedRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => <HomePage {...props} />}
            />
            <LayoutUnprotectedRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => <AuthPage {...props} authState="LOGIN" />}
            />
            <LayoutUnprotectedRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => <AuthPage {...props} authState="SIGNUP" />}
            />
            <LayoutUnprotectedRoute
              exact
              path="/forgot-password"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState="FORGOT_PASSWORD" />
              )}
            />
            <LayoutUnprotectedRoute
              exact
              path="/reset/:resetId"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState="RESET_PASSWORD" />
              )}
            />

            <MainLayout breakpoint={breakpoint}>
              <Suspense fallback={<PageSpinner />}>
                <AuthProtectedRoute
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                />
                <AuthProtectedRoute
                  exact
                  path="/account"
                  component={AccountPage}
                />
                <AdminProtectedRoute
                  exact
                  path="/admin/:dept"
                  component={AdminPage}
                />
                <AuthProtectedRoute
                  exact
                  path="/elections/:role"
                  component={PollPage}
                />
                <AuthProtectedRoute
                  exact
                  path="/results/:type"
                  component={ResultsPage}
                />
              </Suspense>
              <Redirect from="*" to="/dashboard" />
            </MainLayout>
          </Switch>
        </StyleWrap>
        {/* </GAListener> */}
      </BrowserRouter>
    </Provider>
  );
};
const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
