import HeroSection from './HeroSection';
import PollingMonitor from './PollingMonitor';
import CtaSection from './CtaSection';

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <div className="py-5 px-2 px-sm-3 px-md-4 px-lg-5">
        <PollingMonitor />
      </div>

      <CtaSection />
    </>
  );
};

export default HomePage;
